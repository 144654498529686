<template>
  <v-container fluid class="dashboard">
    <v-row no-gutters fill-height class="inner-dashboard">
      <dashboard-sidenav></dashboard-sidenav>
      <v-col grow class="content">
        <v-app-bar elevation="1" color="white" class="header">
          <v-row justify-space-between>
            <v-btn icon @click.stop="mini = !mini">
              <v-icon large>{{ mini ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
            </v-btn>
            <div
              class="profile d-flex align-center"
              :class="{ active: isDropdownProfileActive }"
              @click="toggleDropdownProfile"
              v-click-outside="hideDropdownProfile"
            >
              <!-- <v-avatar size="32" color="grey lighten-4">
                <img src="@/assets/image/avatar_placeholder.svg" alt="avatar" />
              </v-avatar> -->
              <!-- <span>{{ name }} - {{ company }}</span> -->
              <v-menu offset-y max-width="200" nudge-bottom="20">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on"> {{ name }} - {{ company }} </v-btn>
                </template>
                <v-list class="usermenu">
                  <v-list-item>
                    <v-btn text @click="resetPassword()">
                      Ganti Sandi
                    </v-btn>
                  </v-list-item>

                  <v-list-item>
                    <v-btn text @click="logout">Logout</v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <v-spacer></v-spacer>

            <div class="right-menu">
              <v-btn color="red" v-if="isAdmin && isDemo" @click="resetDemo()">
                Reset Demo
              </v-btn>
              <!-- <v-progress-circular
                v-if="loadingNotification"
                indeterminate
                color="primary"
                size="50"
              ></v-progress-circular> -->

              <!-- <v-menu
                @click="readNotification()"
                v-else
                offset-x
                left
                max-height="500"
                max-width="200"
                nudge-left="20"
                nudge-bottom="20"
                min-width="500"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon x-large v-bind="attrs" v-on="on">
                    <div v-if="unreadedNotification > 0" class="notification-badge" role="status">
                      {{ unreadedNotification }}
                    </div>
                    <v-icon>mdi-bell</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>{{ unreadedNotification }} Pemberitahuan Baru</v-card-title>
                  <v-card-text>
                    <v-card class="mt-5" v-for="(item, index) in notifications" :key="index">
                      <v-card-title
                        >{{ item.sender }}
                        <div v-if="!item.isRead" class="notification-badge" role="status"></div>
                      </v-card-title>
                      <v-card-subtitle>{{ item.created_at }}</v-card-subtitle>
                      <v-card-text
                        ><span class="notification-message">{{ item.message }}</span></v-card-text
                      >
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-menu> -->
            </div>
            <v-spacer />
            <div class="d-flex align-center blink" v-if="highlightAlert.isShow" style="color: red;">
              {{ highlightAlert.isBefore ? highlightAlert.titleBefore : highlightAlert.titleAfter }}
            </div>
          </v-row>
        </v-app-bar>
        <v-container fluid class="inner-content ma-0 pa-0">
          <router-view />
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Swal from 'sweetalert2'
import Constant from '@/const'
import ConstantMixin from '@/constantMixin'
import DashboardSidenav from '../components/DashboardSideNav'

// import eventBus from '@/eventBus'

const moment = require('moment')
const axios = require('axios')

export default {
  name: 'DashboardPage',
  mixins: [ConstantMixin],
  components: {
    DashboardSidenav
  },
  data() {
    return {
      mini: true,
      isAdmin: false,
      isDemo: false,
      name: '',
      company: '',
      isDropdownProfileActive: false,
      loadingNotification: true,
      notifications: [],
      highlightAlert: {
        titleBefore: '',
        titleAfter: '',
        targetDate: '',
        beforDateDay: 5,
        afterDateDay: 5,
        isBefore: true,
        isShow: false
      }
    }
  },
  watch: {
    mini(val) {
      this.$store.commit('sidenavmini', val)
    }
  },
  created() {
    // eventBus.$socket.client.on('notification', data => {
    //   this.getNotification()
    // })
  },

  mounted() {
    this.$nextTick(() => {
      this.name = this.$store.getters.userLoggedIn.name
      this.company = this.$store.getters.userLoggedIn.company.name
      this.isDemo = this.$store.getters.userLoggedIn.isDemo
      this.isAdmin = this.$store.getters.userLoggedIn.role == 'administrator'
      this.getNotification()
      this.getPaymentAlert()
      this.setHighlightAlert()
    })
  },
  computed: {
    unreadedNotification() {
      return this.notifications.filter(item => item.read_at === null).length
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    route() {
      return this.$route.path
    },
    routeSplit() {
      return this.$route.path.split('/')
    },
    routeController() {
      return this.routeSplit[1]
    }
  },
  methods: {
    setHighlightAlert() {
      this.highlightAlert.titleBefore =
        'Perubahan Iuran mulai tanggal 1 Februari 2025, Pastikan Akad Januari didaftarkan pada bulan Januari'
      this.highlightAlert.titleAfter =
        'Perubahan Iuran mulai tanggal 1 Februari 2025, Pastikan Produk yang dipilih sudah sesuai'
      this.highlightAlert.targetDate = moment()
        .startOf('year')
        .add(1, 'month')
        .format()
      this.highlightAlert.beforDateDay = 5
      this.highlightAlert.afterDateDay = 0

      const dayDiff = moment(this.highlightAlert.targetDate).diff(moment(), 'day')
      if (this.highlightAlert.beforDateDay && dayDiff > 0 && dayDiff <= this.highlightAlert.beforDateDay) {
        this.highlightAlert.isShow = true
        this.highlightAlert.isBefore = true
      }
      // debugger
      // if (this.highlightAlert.afterDateDay && dayDiff < this.highlightAlert.afterDateDay * -1) {
      //   this.highlightAlert.isShow = true
      //   this.highlightAlert.isBefore = false
      // }
    },

    getPaymentAlert() {
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(`/payment/alert?company=${this.$store.getters.userLoggedIn.company.id}`)
          )
          .then(response => {
            if (response.status == 200) {
              const payment = response.data.data
              if (payment.length > 0) {
                let listOfPayment = payment.map(item => {
                  return `<br/> Batch ${item.batch} - ${item.periode}`
                })
                Swal.fire({
                  title: 'Pemberitahuan Pembayaran',
                  html: `<span><b>${payment.length}</b> pembayaran menunggu bukti transfer</span>
                  <br/>
                  <span>

                      ${listOfPayment.join('')}

                  </span>
                  `,
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Lihat',
                  cancelButtonText: 'Tutup',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(result => {
                  if (result.value) {
                    this.$router.push('/subscription/payment')
                  }
                })
              }
            }
          })
      })
    },
    getNotification() {
      this.loadingNotification = true
      this.runApiCalls(() => {
        axios.get(Constant.apiUrl.concat('/notification?page=1')).then(response => {
          if (response.status == 200) {
            const notification = response.data.data
            this.notifications = notification
          }
          this.loadingNotification = false
        })
      })
    },
    readNotification(id_notification) {
      this.runApiCalls(() => {
        axios.post(Constant.apiUrl.concat('/notification?page=1')).then(response => {
          if (response.status == 200) {
            const notification = response.data.data
            this.notifications = notification
          }
          this.loadingNotification = false
        })
      })
    },
    toggleDropdownProfile() {
      this.isDropdownProfileActive = !this.isDropdownProfileActive
    },
    hideDropdownProfile() {
      this.isDropdownProfileActive = false
    },
    logout() {
      delete axios.defaults.headers.Authorization
      localStorage.removeItem('token')
      this.$store.commit('removeToken')
      this.$router.push('/login')
      // document.location.reload(true)
    },
    resetDemo() {
      Swal.fire({
        title: 'Reset Demo',
        text:
          'Apakah anda yakin ingin mereset demo?, data demo anggota, peserta dan pembayaran akan dihapus ',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak'
      }).then(result => {
        if (result.value) {
          this.runApiCalls(() => {
            axios
              .post(Constant.apiUrl.concat('/master/company/reset-demo'))
              .then(response => {
                if (response.status === 200) {
                  Swal.fire({
                    type: 'success',
                    title: 'Berhasil',
                    text: 'Demo berhasil direset'
                  })
                }
              })
              .catch(error => {
                if (error.response) {
                  const message = error.response.data.pesan
                  Swal.fire({
                    type: 'error',
                    title: 'Terjadi Kesalahan',
                    text: message
                  })
                } else if (error.request) {
                  Swal.fire({
                    type: 'error',
                    title: 'Terjadi Kesalahan',
                    text: 'Tidak dapat menyambung ke server'
                  })
                } else {
                  // eslint-disable-next-line
                  console.log('Something happened in the middle of request')
                }
              })
          })
        }
      })
    },

    resetPassword() {
      const id = this.$store.getters.userLoggedIn.id
      Swal.fire({
        title: 'Ganti Kata Sandi',
        html:
          '<label>Minimal 8 karakter</label><div></div>' +
          '<label>kombinasi huruf kapital, huruf kecil, angka</label>' +
          '<input id="password" type="password" placeholder="Kata sandi baru" class="swal2-input">' +
          '<input id="rePassword" type="password" placeholder="Ulang kata sandi baru" class="swal2-input">',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Simpan',
        cancelButtonText: 'Batal'
      }).then(result => {
        if (result.value) {
          const pass = document.getElementById('password').value
          const rePass = document.getElementById('rePassword').value
          if (pass === rePass) {
            // /^
            //   (?=.*\d)          // should contain at least one digit
            //   (?=.*[a-z])       // should contain at least one lower case
            //   (?=.*[A-Z])       // should contain at least one upper case
            //   [a-zA-Z0-9!@#$%&?"']{8,}   // should contain at least 8 from the mentioned characters
            // $/
            if (pass.match(Constant.passwordAuth)) {
              axios
                .post(Constant.apiUrl.concat('/master/staff/reset'), {
                  id,
                  password: pass
                })
                .then(response => {
                  if (response.status === 200) {
                    Swal.fire({
                      type: 'success',
                      title: 'Berhasil',
                      text: 'Kata sandi berhasil diperbarui'
                    })
                  }
                })
                .catch(error => {
                  if (error.response) {
                    const message = error.response.data.pesan
                    Swal.fire({
                      type: 'error',
                      title: 'Terjadi Kesalahan',
                      text: message
                    })
                  } else if (error.request) {
                    Swal.fire({
                      type: 'error',
                      title: 'Terjadi Kesalahan',
                      text: 'Tidak dapat menyambung ke server'
                    })
                  } else {
                    // eslint-disable-next-line
                    console.log('Something happened in the middle of request')
                  }
                })
            } else {
              Swal.fire({
                type: 'error',
                title: 'Terjadi Kesalahan',
                text: 'Kata sandi tidak sesuai ketentuan'
              })
            }
          } else {
            Swal.fire({
              type: 'error',
              title: 'Terjadi Kesalahan',
              text: 'Kata sandi tidak sama'
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-message {
  white-space: pre-line;
  line-height: 20%;
}
.notification-badge {
  /* circle shape, size and position */
  position: absolute;
  right: -0.7em;
  top: -0.7em;
  min-width: 1.6em; /* or width, explained below. */
  height: 1.6em;
  border-radius: 0.8em; /* or 50%, explained below. */
  border: 0.05em solid white;
  background-color: red;

  /* number size and position */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  color: white;
}
.right-menu {
  margin-right: 5%;
}
.usermenu {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.35);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.9px);
  -webkit-backdrop-filter: blur(10.9px);
  border: 1px solid rgba(255, 255, 255, 0.45);
}
.dashboard {
  background-color: $background-dashboard;
  min-height: 100vh;

  padding: 1em 0em 0em 1em;
  margin: 0;
  .inner-dashboard {
    width: 100%;
    flex-wrap: 0;
    margin: 0;
    .content {
      background-color: white;
    }
    .inner-content {
      max-height: 87vh;
      min-height: 87vh;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 10px;
        background-color: #9ca2a5;
      }
      &::-webkit-scrollbar-thumb {
        width: 10px;
        background-color: #86bcdb;
      }
    }
  }
  .header {
    padding-right: 15px;
    font-weight: 500;
    position: relative;
    z-index: 99;
    font-family: Roboto, sans-serif !important;

    .profile {
      cursor: pointer;
      position: relative;
      .dropdown {
        position: absolute;
        width: 100%;
        z-index: 1;
        display: none;
        &.active {
          display: block;
        }
        & > * {
          color: white;
        }
      }
      & > * {
        margin: 5px;
      }
      &:hover,
      &:focus,
      &.active {
        border-bottom: 2px solid $primary-color;
      }
    }
  }
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
</style>
