<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="headline">{{ this.title }}</span>

      <v-btn
        :disabled="isUploading"
        absolute
        right
        fab
        @click="onCloseButtonClick()"
        small
        text
        color="primary"
      >
        <v-icon dark>
          mdi-close-circle
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle class="justify-center">
      <br />
      <p>
        Jenis file : (.pdf), (.jpg), (.png) <br />
        <span class="red--text">ukuran setiap file max. 10 MB</span>
      </p>
    </v-card-subtitle>
    <v-card-text>
      <div>
        <v-progress-linear v-if="loading" indeterminate class="upload-bar" color="green"></v-progress-linear>
      </div>
      <v-row no-gutters>
        <v-col md="10" offset-md="1">
          <template v-for="(item, fileIndex) in templates">
            <v-row :key="`${fileIndex}-${item.isEnable}`" v-if="fileIndex != templates.length - 1">
              <v-col md="8" align-self="center">
                <label for="member">
                  <h3>
                    <b>{{ item.title }}</b>
                  </h3>
                </label>
              </v-col>
              <v-col md="4" align-self="center">
                <v-row
                  align="center"
                  v-for="(docItem, documentIndex) in item.files"
                  :key="'row1-' + documentIndex"
                  md="6"
                >
                  <v-col md="6">
                    <v-btn
                      small
                      block
                      color="primary"
                      :disabled="docItem.isDelete"
                      depressed
                      @click="previewFile(docItem)"
                    >
                      {{ docItem.isDelete ? 'Akan diHapus' : 'Tampilkan' }}
                    </v-btn>
                  </v-col>
                  <v-col md="6">
                    <v-btn
                      small
                      block
                      color="primary"
                      :disabled="docItem.isDelete"
                      depressed
                      @click="downloadWithAxios(docItem)"
                    >
                      {{ docItem.isDelete ? 'Akan diHapus' : 'Unduh' }}
                    </v-btn>
                  </v-col>
                  <v-col md="6" v-if="!isAdmin">
                    <v-btn
                      v-if="!docItem.isExist"
                      small
                      block
                      color="error"
                      depressed
                      @click="removeFiles(fileIndex, documentIndex)"
                    >
                      Batal
                    </v-btn>
                    <v-btn
                      v-else
                      small
                      block
                      color="error"
                      depressed
                      @click="deleteFiles(fileIndex, documentIndex)"
                    >
                      {{ docItem.isDelete ? 'Batal Hapus' : 'Hapus' }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <br :key="'div1-' + fileIndex" v-if="fileIndex != templates.length - 1" />
            <v-divider :key="'divider-' + fileIndex" v-if="fileIndex != templates.length - 1"></v-divider>
            <br :key="'div2-' + fileIndex" v-if="fileIndex != templates.length - 1" />
          </template>

          <template v-if="!isSave">
            <v-row>
              <v-col md="8" align-self="center">
                <label for="member">
                  <h3>
                    <b>{{ templates[5].title }}</b>
                  </h3>
                </label>
              </v-col>
              <v-col md="4" align-self="center">
                <v-row
                  align="center"
                  v-for="(docItem, documentIndex) in templates[5].files"
                  :key="'row1-' + documentIndex"
                  md="6"
                >
                  <v-col md="6">
                    <!-- <v-btn
                      small
                      block
                      color="primary"
                      :disabled="docItem.isDelete"
                      depressed
                      @click="previewFile(docItem)"
                    >
                      {{ docItem.isDelete ? 'Akan diHapus' : 'Tampilkan' }}
                    </v-btn> -->
                    <v-btn
                      small
                      block
                      color="primary"
                      :disabled="docItem.isDelete"
                      depressed
                      @click="downloadWithAxios(docItem)"
                    >
                      {{ docItem.isDelete ? 'Akan diHapus' : 'Unduh' }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>

          <template v-else>
            <v-row>
              <v-col md="8" align-self="center">
                <label for="member">
                  <h3>
                    <b>{{ templates[5].title }}</b>
                  </h3>
                </label>
              </v-col>
              <v-col md="4" align-self="center" v-if="isSave">
                <v-flex xs6>
                  <input
                    type="file"
                    id="files"
                    ref="files"
                    accept="image/png, image/jpeg, application/pdf"
                    v-on:change="handleFilesUpload()"
                    v-show="false"
                  />

                  <v-btn
                    :disabled="isUploading || loading"
                    depressed
                    block
                    class="upload-button mt-3 mb-2"
                    @click="addFiles()"
                  >
                    Pilih File</v-btn
                  >
                </v-flex>
              </v-col>
            </v-row>
            <br />
            <v-row>
              <v-col
                align-self="center"
                v-for="(docItem, documentIndex) in templates[5].failedFiles"
                :key="'col1-' + documentIndex"
                md="8"
              >
                <v-flex md9 class="mx-2">
                  <p class="text-md-left ma-0">
                    File {{ docItem.file.name }} gagal diunggah karena
                    <span class="yellow--text ">{{ docItem.message }}</span>
                  </p>
                </v-flex>
              </v-col>
              <v-col :key="'col3-' + documentIndex" md="4">
                <!-- <v-flex md1>
                  <v-btn small block color="error" depressed @click="removeWarning(4, documentIndex)">
                    Hapus
                  </v-btn>
                </v-flex> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col
                align-self="center"
                v-for="(docItem, documentIndex) in templates[5].files"
                :key="'col1-' + documentIndex"
                md="6"
              >
                <v-flex md7>
                  <p class="text-md-left">
                    {{ docItem.name || docItem.originalname }}
                  </p>
                </v-flex>
                <v-row align="center" :key="'row1-' + documentIndex" md="6">
                  <v-col md="6">
                    <!-- <v-btn
                      small
                      block
                      color="primary"
                      :disabled="docItem.isDelete"
                      depressed
                      @click="previewFile(docItem)"
                    >
                      {{ docItem.isDelete ? 'Akan diHapus' : 'Tampilkan' }}
                    </v-btn> -->
                    <v-btn
                      small
                      block
                      color="primary"
                      :disabled="docItem.isDelete"
                      depressed
                      @click="downloadWithAxios(docItem)"
                    >
                      {{ docItem.isDelete ? 'Akan diHapus' : 'Unduh' }}
                    </v-btn>
                  </v-col>
                  <v-col md="6" v-if="isSave">
                    <v-btn
                      v-if="!docItem.isExist"
                      small
                      block
                      color="error"
                      depressed
                      @click="removeFiles(5, documentIndex)"
                    >
                      Batal
                    </v-btn>
                    <v-btn v-else small block color="error" depressed @click="deleteFiles(5, documentIndex)">
                      {{ docItem.isDelete ? 'Batal Hapus' : 'Hapus' }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-flex>
              <v-progress-linear class="upload-bar" :value="progress" v-if="isUploading"></v-progress-linear>
              <h4 class="text-md-left" v-if="isUploading">{{ progress }} %</h4>
            </v-flex>
            <br />
            <v-divider></v-divider>
            <br />
          </template>
        </v-col>
      </v-row>
      <br />
      <v-row no-gutters>
        <v-col offset="1" cols="6">
          <label>
            <h3>
              <b>Jumlah Pengajuan </b>
            </h3>
          </label>
        </v-col>
        <v-col cols="3">
          <label>
            <h3>
              <b>{{ getCurrency(claim) }}</b>
            </h3>
          </label>
        </v-col>
        <v-col></v-col>
      </v-row>
      <br />
      <v-row no-gutters>
        <v-col offset="1" cols="6">
          <label>
            <h3>
              <b>Tanggal Kematian </b>
            </h3>
          </label>
        </v-col>
        <v-col cols="3">
          <label>
            <h3>
              <b>{{ passedDate }}</b>
            </h3>
          </label>
        </v-col>
        <v-col></v-col>
      </v-row>
      <br />
      <v-row no-gutters>
        <v-col offset="1" cols="6">
          <label>
            <h3>
              <b>Alasan Santunan </b>
            </h3>
          </label>
        </v-col>
        <v-col cols="3">
          <label>
            <h3>
              <b>{{ cause }}</b>
            </h3>
          </label>
        </v-col>
        <v-col></v-col>
      </v-row>
      <br />
      <v-row no-gutters>
        <v-col offset="1" cols="6">
          <label>
            <h3>
              <b>Jumlah Persetujuan </b>
            </h3>
          </label>
        </v-col>
        <v-col cols="3">
          <v-currency-field
            v-if="!isClaimApproved"
            class="currency-field"
            @keypress="onlyNumber"
            id="claim_approval"
            v-model="claimApproved"
            prefix="Rp."
            dense
            placeholder="Persetujuan Santunan"
            :loading="loading"
          />
          <label v-else>
            <h3>
              <b>{{ getCurrency(claimApproved) }} </b>
            </h3>
          </label>
        </v-col>
        <v-col></v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" width="100" text large @click="onCloseButtonClick()">
        Kembali
      </v-btn>

      <v-tooltip v-if="!isClaimApproved" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="!isClaimApproved"
            v-bind="attrs"
            v-on="on"
            :color="!isPaid ? 'red' : 'primary'"
            width="200"
            @click="claimSubscription()"
            large
            :loading="loading"
          >
            Selesai Santunan
          </v-btn>
        </template>

        <span v-if="!isPaid">Peserta belum melakukan Pembayaran</span>
        <span v-else>Silahkan selesaikan santunan peserta</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
/* eslint-disable no-unused-vars */
import Swal from 'sweetalert2'
import Constant from '@/const'

import ConstantMixin from '@/constantMixin'

const _ = require('lodash')
const axios = require('axios')
const moment = require('moment')

export default {
  mixins: [ConstantMixin],
  props: {
    id_subscription: String,
    id_company: String,
    isSave: Boolean
  },
  data: () => ({
    title: 'Pengajuan Santunan',
    loading: false,
    isUploading: false,
    isAdmin: false,
    isPaid: false,
    isClaimApproved: false,
    progress: 0,
    claim: 0,
    cause: '',
    documents: [],
    memberName: '',
    status: '',
    isBillUploaded: false,
    templates: [
      {
        title: 'Surat Permohonan Santunan',
        isEnable: true,
        files: [],
        failedFiles: []
      },
      {
        title: 'Surat Keterangan Kematian',
        isEnable: true,
        files: [],
        failedFiles: []
      },
      {
        title: 'Kartu Tanda Penduduk dan Kartu Keluarga',
        isEnable: true,
        files: [],
        failedFiles: []
      },
      {
        title: 'Akad Pembiayaan',
        isEnable: true,
        files: [],
        failedFiles: []
      },
      {
        title: 'Riwayat Angsuran Pembiayaan',
        isEnable: true,
        files: [],
        failedFiles: []
      },
      {
        title: 'Bukti Transfer',
        isEnable: true,
        isUploading: false,
        progress: 0,
        files: [],
        failedFiles: []
      }
    ]
  }),
  watch: {
    templates(value) {
      if (value[5].files > 0) this.isBillUploaded = true
    }
  },
  created() {
    this.$socket.client.off('claim')
    this.$socket.client.on('claim', data => {
      this.getSubscription()
    })
  },
  mounted() {
    if (_.toLower(this.$store.getters.userLoggedIn.role) == 'administrator') this.isAdmin = true
    else this.templates[5].isEnable = false
    this.getSubscription()
  },
  methods: {
    claimSubscription() {
      try {
        if (this.isPaid)
          Swal.fire({
            title: 'Anda Yakin ?',
            text: `Menyelesaikan santunan untuk ${this.memberName} Santunan Disetujui : ${this.getCurrency(
              this.claimApproved
            )}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
          }).then(result => {
            if (result.value) {
              this.runApiCalls(() => {
                axios
                  .put(
                    Constant.apiUrl.concat(
                      '/subscription/status/',
                      this.id_subscription,
                      '?company=',
                      this.id_company
                    ),
                    {
                      isComplete: true,
                      claim: {
                        periode: {
                          month: moment()
                            .locale('id')
                            .format('MMMM'),
                          year: moment()
                            .locale('id')
                            .format('YYYY')
                        },
                        status: 'approved',
                        description: '',
                        claim_approved: this.claimApproved
                      },
                      staff: this.$store.getters.userLoggedIn.id
                    }
                  )
                  .then(response => {
                    if (response.status === 200) {
                      this.$socket.client.emit('claim:update', {
                        company: this.$store.getters.userLoggedIn.company.name,
                        staff: this.$store.getters.userLoggedIn
                      })
                      this.swalToast('success', 'Santunan berhasil diselesaikan')
                      this.getSubscription()
                    }
                  })
                  .catch(error => {
                    if (error.response) {
                      const message = error.response.data.pesan
                      Swal.fire({
                        icon: 'error',
                        title: 'Terjadi Kesalahan',
                        text: message
                      })
                    } else if (error.request) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Terjadi Kesalahan',
                        text: 'Tidak dapat menyambung ke server'
                      })
                    } else {
                      // eslint-disable-next-line
                      console.log('Something happened in the middle of request')
                    }
                  })
              })
            }
          })
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Tidak dapat menyambung ke server'
        })
      }
    },
    getSubscription() {
      try {
        this.loading = true
        axios.get(Constant.apiUrl.concat('/subscription/', this.id_subscription)).then(response => {
          const subs = response.data.data

          this.claim = subs.claim
          this.cause = subs.claim_cause
          this.claimApproved = subs.claim_approved || subs.claim
          if (subs.passed_date)
            this.passedDate = moment(subs.passed_date)
              .locale('id')
              .format('D MMMM YYYY')

          this.isClaimApproved = subs.claim_status[0].status == 'approved' ? true : false

          if (subs.paid_status[0].status == 'approved') this.isPaid = true

          this.memberName = subs.member_name
          // if (['102', '202'].indexOf(subs.product_code) == -1) this.templates[3].isEnable = false
          for (let i = 0; i < this.templates.length; i++) {
            const template = this.templates[i]
            const document = subs.claim_document.find(v => v.name == template.title.toLowerCase())

            if (template.title.toLowerCase() == 'bukti transfer') {
              if (_.get(document, 'files.length', 0) > 0) {
                this.isBillUploaded = true
              }
            }
            if (document) {
              this.templates[i].files = document.files
            }
          }

          this.loading = false
        })
      } catch (error) {
        this.loading = false
      }
    },
    handleFilesUpload() {
      const filesUploaded = this.$refs.files.files[0]
      if (!this.verify(filesUploaded)) {
        if (this.templates[5].files.length > 0) this.templates[5].files = []
        this.templates[5].files.push(filesUploaded)
        this.submitFiles()
      } else {
        if (this.templates[5].failedFiles.length > 0) this.templates[5].failedFiles = []
        this.templates[5].failedFiles.push({
          file: filesUploaded,
          message: this.verify(filesUploaded)
        })
      }
      this.$refs.files.value = ''
    },
    async submitFiles(isDelete) {
      this.isUploading = true
      const formData = new FormData()
      this.templates.forEach((template, index) => {
        template.files.forEach(file => {
          formData.append(`files-${index}`, file.isExist ? JSON.stringify(file) : file)
        })
      })
      formData.append('identifier', this.id_subscription)
      formData.append('tag', 'claimDocument')

      await axios
        .post(Constant.apiUrl.concat('/subscription/upload'), formData, {
          onUploadProgress: progressEvent => {
            this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          },
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.isUploading = false
            this.$socket.client.emit('claim:update', {
              company: this.$store.getters.userLoggedIn.company.name,
              staff: this.$store.getters.userLoggedIn
            })
            this.getSubscription()
            this.swalToast('success', isDelete ? 'Dokumen berhasil dihapus' : 'Dokumen berhasil diunggah')
          }
        })
        .catch(error => {
          this.isUploading = false
          this.showErrorAxios(error)
        })
    },
    addFiles() {
      this.$refs.files.click()
    },
    downloadWithAxios(file) {
      axios
        .get(Constant.apiUrl.concat(`/${file.path}`), { responseType: 'arraybuffer' })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          const memberName = file.path
            .split('/')
            [file.path.split('/').length - 4].split(' ')[0]
            .toLowerCase()
          const fileName = file.path
            .split('/')
            [file.path.split('/').length - 2].split(' ')
            .join('_')
          const fileType = file.path
            .split('/')
            [file.path.split('/').length - 1].split('.')
            [file.path.split('/')[file.path.split('/').length - 1].split('.').length - 1].toLowerCase()

          link.href = url
          link.setAttribute('download', `${memberName}-${fileName}.${fileType}`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => {})
    },
    previewFile(file) {
      let url = ''
      if (typeof file === 'object') url = Constant.apiUrl.concat(`/${file.path}`)
      else url = URL.createObjectURL(file)
      window.open(url)
    },
    removeFiles(index, fileIndex) {
      this.templates[index].files.splice(fileIndex, 1)
    },
    deleteFiles(index, fileIndex) {
      this.templates[index].files[fileIndex].isDelete = !this.templates[index].files[fileIndex].isDelete
      this.submitFiles(index)
    },
    removeWarning(index, fileIndex) {
      this.templates[index].failedFiles.splice(fileIndex, 1)
    },
    verify(file) {
      const maxSize = 10000000
      const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf']
      if (file.size > maxSize) {
        return 'Maksimum Ukuran file 10 MB'
      }

      const ext = file.name.split('.')

      if (!allowedTypes.includes(file.type)) {
        return `Tipe file .${ext[1]} tidak diperbolehkan`
      }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label-header {
  color: #222;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    flex: 1;
    display: flex;
    color: #222;
  }

  &__input-text {
    margin-left: 10px;
    max-width: 60%;
  }
}

input:placeholder-shown {
  font-size: 0.5em;
}
</style>
